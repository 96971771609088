import React, {Component} from 'react'
//import logo from './logo.svg'
import './App.css'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom"

//import pingpong from './webworkers/pingpong.worker.js'

import SimpleMap from './components/map/Map'
import LocationPaper from './components/LocationPaper'
import LocationSelect from './components/LocationSelect'

//const pingpong = require('./webworkers/pingpong.worker.js')
//let pingpong_ww = new pingpong()
let pingpong_ww = new Worker(new URL('./webworkers/pingpong.worker.js', import.meta.url));

let rotatingBoxes = () => {
  return (
    <div className='outer'>
      <div className='rotatingBoxes1'/>
      <div className='rotatingBoxes2'/>
      <div className='rotatingBoxes3'/>
    </div>
  )
}

let portfolioButton = () => {
  return (
    <div
      //onClick={()=>window.open('https://portfolio.jamalmahboob.com')}
      className='portfolioButton'>Jamal Mahboob - Portfolio
    </div>
  )
}

let mapButton = () => {
  return (
    <div
      //onClick={()=>window.open('https://hud.jamalmahboob.com/map')}
      className='portfolioButton'>Google Cloud Map Ping
    </div>
  )
}

function Home() {
  return (
   <div className="App">
      <div className="App-header">
        {/*}<img src={logo} className="App-logo" alt="logo" />*/}
        { rotatingBoxes() }
        <p style={{paddingTop: '45px'}}>
          UI / UX HUD Coming Soon!
        </p>
        {/*<Link to="/"> { portfolioButton() } </Link>*/}
        <Link to="/map"> { mapButton() } </Link>
        {/*<TestWebWorker />*/}
      </div>
    </div>
  )
}

let TestWebWorker = () => {
  return (
    <div
      onClick={() => pingpong_ww.postMessage("test")}
      className='portfolioButton'>Test WebWorker
    </div>
  )
} 
class App extends Component {

  constructor(props) {
    super(props)
  }

  componentDidMount = () => {
  }

  render() {
  
    return (
      <Router>
        <div>
          <Switch>
            <Route path="/playground/locationpaper">
              <div className='centerCenter'>
                <LocationPaper />
              </div>
              <LocationSelect />
            </Route>
            <Route path="/map">
              <RouteMap />
            </Route>
            <Route path="/">
              <RouteHome />
            </Route>
          </Switch>
        </div>
      </Router>
    )
  }
}

function RouteHome() {
  return Home() 
}

function RouteMap() {
  return <SimpleMap />
}

export default App
