import { createStore } from 'redux'
import rootReducer from '../reducers/rootReducer'

// There's something off with this, need to figure out how to webpack / bundle a json file properly
// You can see the bug with gcp_zones = gcp_zones.gcp_zones, seems super redundant
var gcp_zones = require('../assets/gcp_zones.json')

let initialState = {
  'test': {
    'testMarker': { lng: -77.0369, lat: 38.9072 }
  },
  'active_zone': 'us-east4',
  'gcp_zones': gcp_zones.gcp_zones,
  'modals': {
    'faq': false,
    'inspiration': false,
    'acknowledgements': false,
    'locationplayground': false
  }
}

export default createStore(
  rootReducer,
  initialState,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)
