import React, {Component} from 'react'
import { connect } from 'react-redux'

import Modal from '@material-ui/core/Modal'

class FAQModal extends Component {

  handleClose = () => {
    this.props.dispatch({ type: 'CLOSE_MODAL', modal: 'faq' })
  }
  
  render() {
    return (
      <Modal
        open={this.props.open}
        onClose={this.handleClose}
      >

        <div className='rootModal'>
          <div className='rootModalHeader'> F.A.Q. </div>
          <p> John wants to know how we pulled lat/lng for each location.  You see, we hooked GPS modules up to carrier pigeons and then... </p>
          <p> No I just entered the city into Google Maps and then copy/pasted the lat/lng values from the URL. </p>
        </div>

      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    open: state.modals.faq
  }
}

export default connect(
  mapStateToProps
)(FAQModal)
