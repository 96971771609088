import React, { Component } from 'react'
import GoogleMapReact from 'google-map-react'
import mapStyle from './MapStyle.json'
import testStyle from './TestStyle.json'

import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import { makeStyles, withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MenuIcon from '@material-ui/core/Menu'
import Icon from '@material-ui/core/Icon'
import ListItemIcon from '@material-ui/core/ListItemIcon'

import FAQModal from '../modals/FAQModal'
import InspirationModal from '../modals/InspirationModal'
import AcknowledgementModal from '../modals/AcknowledgementModal'

import LocationPaper from '../LocationPaper'
import LocationSelect from '../LocationSelect'
import SideLadder from '../sideLadder/SideLadder'

import { appendRegionDelay, setRegionPerformance } from '../../actions/rootActions'

//import pingpong from '../../webworkers/pingpong.worker.js'

const useStyles = makeStyles(theme => ({
  root: {
    '& > svg': {
      margin: theme.spacing(2),
    }
  }
}))

//let pingpong_ww = new pingpong()
let pingpong_ww = new Worker(new URL('../../webworkers/pingpong.worker.js', import.meta.url));

let rotatingBoxes = (size) => {
  return (
    <div className='outer' style={{width: size, height: size, float:'right', marginLeft:'7px'}}>
      <div className='rotatingBoxes1'/>
      <div className='rotatingBoxes2'/>
      <div className='rotatingBoxes3'/>
    </div>
  )
}

let handleApiLoaded = (map, maps) => {
  console.log(map);
}

class MapHeader extends Component {

  render() {
    return (
      <div className='mapHeader'>
        { rotatingBoxes('45px') }
        <Link to="/">
          <div className='portfolioButton'> Home </div>
        </Link>
        <Link to="/playground/locationpaper">
          <div className='portfolioButton'>Location Paper</div>
        </Link>
        <div className='portfolioButton' onClick={() => pingpong_ww.postMessage('35.186.168.152/ping')}>
          Test WebWorker
        </div>
      </div>
    )
  }

}

class HamburgerMenu extends Component {

  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }
  }

  handleClick = (e) => {
    this.setState({
      ...this.state,
      open: !this.state.open,
      anchor: e.currentTarget
    })
  }

  handleClose = modal => {
    this.setState({ 
      ...this.state,
      open: false
    })
    console.log('test', modal)
    this.props.dispatch({type:'OPEN_MODAL', modal: modal})
  }

  render() {
    return (
      <div className='hamburgerHeader'>
        <Button onClick={this.handleClick} style={{color:'white'}}>
          <Icon fontSize="large">menu</Icon>
        </Button>
        <Menu
          id='hamburger-menu'
          open={this.state.open}
          onClose={this.handleClose}
          keepMounted
          anchorEl={this.state.anchor}
          style={{color:'red'}}
        >
          <MenuItem onClick={(modal) => this.handleClose('faq')}> FAQ </MenuItem>
          <MenuItem onClick={(modal) => this.handleClose('inspiration')}> Inspiration </MenuItem>
          <MenuItem onClick={(modal) => this.handleClose('acknowledgement')}> Acknowledgements </MenuItem>
          <MenuItem onClick={() => window.location.assign('/playground/locationpaper')}> Location Playground </MenuItem>
        </Menu>
      </div>
    )
  }
}

class Marker extends Component { 

  constructor(props) {
    super(props)
  }

  renderData = () => {
    let key = this.props.data
    let perf = key.perf ? key.perf : ''
    
    return (
      <div>
        <div><u>Resource Timing API</u></div>
        <div style={{paddingLeft:'10px'}}>
          <div> Start Time </div> <div> -- { perf.startTime } </div>
          <div> Fetch Start </div> <div> -- { perf.fetchStart } </div>
          <div> Domain Start </div> <div> -- { perf.domainLookupStart } </div>
          <div> Domain End </div> <div> -- { perf.domainLookupEnd } </div>
          <div> Connect Start </div> <div> -- { perf.connectStart } </div>
          <div> Secure Start </div> <div> -- { perf.secureConnectionStart } </div>
          <div> Connect End </div> <div> -- { perf.connectEnd } </div>
          <div> Request Start </div> <div> -- { perf.requestStart } </div>
          <div> Response Start </div> <div> -- { perf.responseStart } </div>
          <div> Response End </div> <div> -- { perf.responseEnd } </div>
        </div>
      </div>
    )
  }

  render() {
    let key = this.props.data
    let perf = key.perf ? key.perf : ''

    // Hand jamming a lng offset to show marker at 25vw - need to math it a little better
    /*let activeLocation = {
      lat: lat == undefined ? 0 : lat - 0.03,
      lng: lng == undefined ? 0 : lng + 0.05
    }*/

    return (
      <div className='testMarker' lat={key.lat} lng={key.lng}>
        <div>
          <div className='markerBoxes'> { rotatingBoxes('15px') } </div> <div><b> {this.props.region} </b></div>
          {this.props.active_region == this.props.region ? this.renderData() : ''}
        </div>
      </div>
    )
  }

}


class SimpleMap extends Component {

  componentDidMount = () => {
    console.log(this.props, pingpong_ww)
    //pingpong_ww.postMessage('test')

    // setInterval here to murder ping servers
    let pingInterval = setInterval( () => {
      Object.entries(this.props.regions).map( key => {
        pingpong_ww.postMessage([
          key[1].pingpong.split('//')[1],
          key
        ])
      })
    }, 1000)

    pingpong_ww.onmessage = e => {
      //console.log(e.data)
      this.props.dispatch(
        appendRegionDelay(
          e.data[0], e.data[1]
        )
      )
      this.props.dispatch(
        setRegionPerformance(
          e.data[0], e.data[2]
        )
      )
    }

    this.state = {
      modals: {
        FAQ: false
      } 
    }
  }


  static defaultProps = {
    center: {
      //lat: 38.9583176, lng: -77.359787
      //lat: 40, lng: 0
      lat: 38.9072, lng: -77.0369
    },
    zoom: 12
  }

  createMapOptions = (maps) => {
    return {
      panControl: true,
      mapTypeControl: false,
      disableDefaultUI: true,
      minZoom: 3,
      maxZoom: 12,
      styles: mapStyle.mapStyle
      //styles: testStyle.testStyle
      //styles: [{ "featureType": "all", "elementType": "all", "stylers": [{ 'saturation': -100 }, { 'gamma': 0.8 }, { 'lightness': 4 }, { 'visibility': 'on' }] }]
    }
  } 

  handleChildClick = (key, childProps) => {
    console.log('handleChildClick', childProps)
  }

  render() {

    /*console.log( [{ stylers: [{ 'saturation': -100 }, { 'gamma': 0.8 }, { 'lightness': 4 }, { 'visibility': 'on' }] }] )
    console.log( testStyle.testStyle )
    console.log(this.props.test.testMarker.lat)*/

    let lat = this.props.regions[this.props.active_region].lat
    let lng = this.props.regions[this.props.active_region].lng
    let activeLocation = {
      lat: lat == undefined ? 0 : lat - 0.03,
      lng: lng == undefined ? 0 : lng + 0.05
    }

    return (
      <div style={{height: '100vh', width: '100vw'}}>
        {/*<MapHeader />*/}
        <ConnectedHamburgerMenu />

        <FAQModal />
        <InspirationModal />
        <AcknowledgementModal />

        <GoogleMapReact
          bootstrapURLKeys={{key:'AIzaSyAQQUjPRUpec1PytF0QzfdM7AOFs2IwOgE'}}
          defaultCenter={activeLocation}
          defaultZoom={this.props.zoom}
          center={activeLocation}
          zoom={12}
          options={this.createMapOptions}
          yesIWantToUseGoogleMapApiInternals
          onChildClick={this.handleChildClick}
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map,maps)}
        >
          { Object.keys(this.props.regions).map( (key,value) => {
            return ( <Marker
              lat={this.props.regions[key].lat} lng={this.props.regions[key].lng}
              data={this.props.regions[key]}
              region={key}
              active_region={this.props.active_region}/> )
          }) }
        </GoogleMapReact>
        <div className='bottomLeft'>
          <LocationPaper />
        </div>
        <div className='sideRight'>
          <SideLadder />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    test: state.test,
    regions: state.gcp_zones,
    active_region: state.active_zone
  }
}

const mapDispatchToProps = dispatch => {
  return {
    appendRegionDelay: (region, delay) => dispatch({ type: "APPEND_REGION_DELAY", data: region, delay }),
    dispatch
  }
}

const ConnectedHamburgerMenu = connect()(HamburgerMenu)

export default connect(
  mapStateToProps, mapDispatchToProps
)(SimpleMap)


