import React, {Component} from 'react'
import { connect } from 'react-redux'

import Modal from '@material-ui/core/Modal'

class AcknowledgementModal extends Component {

  handleClose = () => {
    this.props.dispatch({ type: 'CLOSE_MODAL', modal: 'acknowledgement' })
  }
  
  render() {
    return (
      <Modal
        open={this.props.open}
        onClose={this.handleClose}
      >

        <div className='rootModal'>
          <div className='rootModalHeader'> Ack </div>
          <div className='rootModalSubHeader'> Baron Neue Font </div>
          <p>
            <a href="https://www.fontfabric.com/fonts/baron/">Baron Neue</a> Font courtesy of FontFabric. 
          </p>
          <hr/>

          <div className='rootModalSubHeader'> Favicon </div>
          <p>
            Favicon courtsey of <a href="https://favicon.io">Favicon.io</a>.
          </p>
        </div>

      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    open: state.modals.acknowledgement
  }
}

export default connect(
  mapStateToProps
)(AcknowledgementModal)
