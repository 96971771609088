import React, { Component } from 'react'
import { connect } from 'react-redux'

import { setActiveRegion } from '../../actions/rootActions'

class SideLadder extends Component {

  handleClick = event => {
    console.log(event.target.id)
    console.log(event.target)
    event.stopPropagation()
    this.props.dispatch( 
      setActiveRegion(
        event.target.id
      )
    ) 
  }

  componentDidMount = () => {
    //console.log("SideLadder Did Mount")
  }

  shouldComponentUpdate(nextProps, nextState) {
    return true
  }

  average = arr => {
    let sum = 0
    arr.map( elem => { sum += elem } )

    return Math.floor( sum / arr.length )
    // Testing array min instead of average
    //return Math.floor( Math.min( ...arr ) )
  }

  render() {
    let fontSize = window.innerWidth > 1000 ? '16px' : '1.8vmin'
    console.log(window.innerWidth, fontSize)
    return (
      <div className='sideLadderWrapper' style={{fontSize:fontSize}}>
        { Object.keys( this.props.regions ).map( key => {

          let delay = this.props.regions[key].delay
          let _avg = this.average(delay)
          _avg = _avg ? _avg : Math.floor( Math.random() * (0, 300) + 0 )
          var color = 'rgba(219,68,55, 0.7)'
          if (_avg < 250) color = 'rgba(244, 180, 0, 0.7)'
          if (_avg < 100) color = 'rgba(15, 157, 88, 0.7)'

          return (
            <div className='sideLadderItem' style={{borderLeftColor: color}} onClick={this.handleClick} key={key} id={key}>
              <span className='sideLadderItemDelay'>{ _avg }<span className='milliseconds'>ms</span></span>
              <span>{ key }</span>
            </div>
          )
        })}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    regions: state.gcp_zones
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setActiveRegion: (region) => dispatch({ type: "SET_ACTIVE_REGION", data: region}),
    dispatch
  }
}

export default connect(
  mapStateToProps, mapDispatchToProps
)(SideLadder)
