import React, { Component } from 'react'
import { connect } from 'react-redux'

class LocationPaper extends Component {
  render() {
    console.log(this.props)
    return (
      <div className="locationPaperWrapper" key={this.props.active_zone}>
        <div className="locationPaperRegion">
          { this.props.active_zone }
        </div>
        <div className="locationPaperReal">
          { this.props.region_info.location }
        </div>
        <div className="locationPaperCoordinates">
          Lat: { this.props.region_info.lat }, Lng: { this.props.region_info.lng}  |  { this.props.region_info.zones }
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    active_zone: state.active_zone,
    region_info: state.gcp_zones[state.active_zone]
  }
}

export default connect(
  mapStateToProps
)(LocationPaper)
