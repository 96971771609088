import React, {Component} from 'react'
import { connect } from 'react-redux'

import Modal from '@material-ui/core/Modal'

class InspirationModal extends Component {

  handleClose = () => {
    this.props.dispatch({ type: 'CLOSE_MODAL', modal: 'inspiration' })
  }
  
  render() {
    return (
      <Modal
        open={this.props.open}
        onClose={this.handleClose}
      >

        <div className='rootModal'>
          <div className='rootModalHeader'> Inspo </div>
          <div className='rootModalSubHeader'> GCPING.COM </div>
          <p>
            Pretty much this entire website is inspired by the fantastic work at <a href='http://gcping.com'>GCPING</a>.  Go check it out, the entire source is posted on the developers GitHub.
          </p>
          <hr/>
          <div className='rootModalSubHeader'> Destiny 2 UI </div>
          <p>
            Thanks to Google Stadia, over the 2019 Holidays I had brand new access to the entirety of Destiny 2.  I fell in love with both the game and the UI, and the final obsession came when I discovered the developers full Visual Design Guide on Behance @ <a href="https://www.behance.net/gallery/60073341/Destiny-2-UI-Visual-Design">Destiny 2 Visual Design</a>.
          </p>
        </div>

      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    open: state.modals.inspiration
  }
}

export default connect(
  mapStateToProps
)(InspirationModal)
