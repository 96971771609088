import React, { Component } from 'react'
import { connect } from 'react-redux'

import { setActiveRegion } from '../actions/rootActions'


class LocationSelect extends Component {

  handleChange = (event) => {
    this.props.dispatch(
      setActiveRegion(
        event.target.value
      )
    )
  }

  render() {
    return (
      <div>
        <select onChange={this.handleChange}>
          { Object.keys(this.props.regions).map( key => {
            return(<option value={key}>{key}</option>)
          })}
        </select>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    regions: state.gcp_zones
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setActiveRegion: (region) => dispatch({ type: "SET_ACTIVE_REGION", data: region }),
    dispatch
  }
}

export default connect(
  mapStateToProps, mapDispatchToProps
)(LocationSelect)
