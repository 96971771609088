/*let initialState = {
  testStringKey: 'testStringValue',
  testArrayKey: ['testArrayValue'],
  testObjectKey: {
    'key': 'testObjectValue'
  },
  gcp_zones
}*/

let initialState = {}

function rootReducer(state = initialState, action) {
  let newState = JSON.parse(JSON.stringify(state))
  switch(action.type) {
    case 'SET_ACTIVE_REGION':
      return Object.assign({}, state, {
        active_zone: action.region
      })
    case 'APPEND_REGION_DELAY':
      newState.gcp_zones[action.region].delay.push(action.delay)
      return newState
    case 'SET_REGION_PERFORMANCE':
      newState.gcp_zones[action.region].perf = action.perf
      return newState
    case 'CLOSE_MODAL': 
      newState.modals[action.modal] = false
      return newState
    case 'OPEN_MODAL':
      newState.modals[action.modal] = true
      return newState
    default:
      return state
  }
}

export default rootReducer
