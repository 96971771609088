export const SET_ACTIVE_REGION = 'SET_ACTIVE_REGION'
export const APPEND_REGION_DELAY = 'APPEND_REGION_DELAY'
export const SET_REGION_PERFORMANCE = 'SET_REGION_PERFORMANCE'

export const CLOSE_MODAL = 'CLOSE_MODAL'
export const OPEN_MODAL = 'OPEN_MODAL'

export function setActiveRegion(region) {
  return {
    type: SET_ACTIVE_REGION,
    region: region
  }
}

export function appendRegionDelay(region, delay) {
  return {
    type: APPEND_REGION_DELAY,
    region: region,
    delay: delay
  }
}

export function setRegionPerformance(region, perf) {
  return {
    type: SET_REGION_PERFORMANCE,
    region: region,
    perf: perf
  }
}

export function closeModal(modal) {
  return {
    type: CLOSE_MODAL,
    modal: modal
  }
}

export function openModal(modal) {
  return {
    type: OPEN_MODAL,
    modal: modal
  }
}
